import http from "../http-common";

const getAll = async (params) => {
  const res = await http.get("/trucks", {
    params: params
  });
  return res.data;
};

const get = async (id) => {
  const res = await http.get(`/trucks/${id}`);
  return res.data;
};

const create = async (data) => {
  return http.post("/trucks", data);
};

const update = async (id, data) => {
  const res = await http.put(`/trucks/${id}`, data);
  return res.data;
};

const remove = async (id) => {
  const res = await http.delete(`/trucks/${id}`);
  return res.data;
};


const TruckService = {
  getAll,
  get,
  create,
  update,
  remove,
};

export default TruckService;