import { NavLink } from "react-router-dom";
import React from "react";

const SideMenu = () => {
  return (
    <div
      id="kt_aside"
      className="aside aside-dark aside-hoverable "
      data-kt-drawer="true"
      data-kt-drawer-name="aside"
      data-kt-drawer-activate="{default: true, lg: false}"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction="start"
      data-kt-drawer-toggle="#kt_aside_mobile_toggle"
    >
      <div className="aside-logo flex-column-auto" id="kt_aside_logo">
        <NavLink to="/dashboard" style={{ backgroundColor: `aliceblue`}}>
          <img
            alt="Logo"
            src="/assets/media/logos/long_logo.png"
            className="logo"
            style={{ width: "220px" }}
          />
        </NavLink>
      </div>
      <div className="aside-menu flex-column-fluid">
        <div
          className="hover-scroll-overlay-y my-5 my-lg-5"
          id="kt_aside_menu_wrapper"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-height="auto"
          data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
          data-kt-scroll-wrappers="#kt_aside_menu"
          data-kt-scroll-offset="0"
        >
          <div
            className="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500"
            id="#kt_aside_menu"
            data-kt-menu="true"
          >
            <div className="menu-item">
              <div className="menu-content pb-2">
                <span className="menu-section text-muted text-uppercase fs-8 ls-1">
                  Dashboard
                </span>
              </div>
            </div>

            <div className="menu-item">
              <NavLink
                className={(navinfo) =>
                  navinfo.isActive ? "menu-link active" : "menu-link"
                }
                to="/dashboard"
              >
                <span className="menu-icon">
                    <i className="fa fa-dashboard"></i>
                  </span>
                <span className="menu-title">Dashboard</span>
              </NavLink>
            </div>

            <div className="menu-item">
              <div className="menu-content pt-8 pb-2">
                <span className="menu-section text-muted text-uppercase fs-8 ls-1">
                  Customers
                </span>
              </div>
            </div>

              <div className="menu-item">
                <NavLink
                  className={(navinfo) =>
                    navinfo.isActive ? "menu-link active" : "menu-link"
                  }
                  to="companies"
                >
                  <span className="menu-icon">
                    <i className="fa fa-building"></i>
                  </span>
                  <span className="menu-title">Companies</span>
                </NavLink>
              </div>
              <div className="menu-item">
                <NavLink
                  className={(navinfo) =>
                    navinfo.isActive ? "menu-link active" : "menu-link"
                  }
                  to="bills"
                >
                  <span className="menu-icon">
                    <i className="fa fa-file"></i>
                  </span>
                  <span className="menu-title">Bills</span>
                </NavLink>
              </div>

              <div className="menu-item">
              <div className="menu-content pt-8 pb-2">
                <span className="menu-section text-muted text-uppercase fs-8 ls-1">
                  Logistics
                </span>
              </div>
            </div>

              <div className="menu-item">
                <NavLink
                  className={(navinfo) =>
                    navinfo.isActive ? "menu-link active" : "menu-link"
                  }
                  to="trucks"
                >
                  <span className="menu-icon">
                    <i className="fa fa-building"></i>
                  </span>
                  <span className="menu-title">Trucks</span>
                </NavLink>
              </div>
              <div className="menu-item">
                <NavLink
                  className={(navinfo) =>
                    navinfo.isActive ? "menu-link active" : "menu-link"
                  }
                  to="trips"
                >
                  <span className="menu-icon">
                    <i className="fa fa-file"></i>
                  </span>
                  <span className="menu-title">Trips</span>
                </NavLink>
              </div>

            <div className="menu-item">
              <div className="menu-content pt-8 pb-2">
                <span className="menu-section text-muted text-uppercase fs-8 ls-1">
                  Authentication
                </span>
              </div>
            </div>

              <div className="menu-item">
                <div
                  className="menu-link"
                  onClick={() => {
                      localStorage.removeItem("user");
                      window.location = "/login";
                    }
                  }
                >
                  <span className="menu-icon">
                    <i className="fa fa-file"></i>
                  </span>
                  <span className="menu-title">Logout</span>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
