import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { inWords } from "helpers/InWords";
import moment from 'moment';
import { Fragment } from 'react';
import logo from './logo.png';
import React from 'react';

const PrintBill = ({billOrder, bills}) => {
    const styles = StyleSheet.create({
        page: {fontSize: 11, padding: 40, paddingTop: 20,lineHeight: 1.5,flexDirection: 'column' },    
    
        theader : {
            marginTop : 20,
            fontSize : 10,
            fontStyle: 'bold',
            paddingTop: 2,
            paddingHorizontal: 2,
            flex:1,
            height:20,
            backgroundColor : '#DEDEDE',
            borderColor : 'black',
            borderRightWidth:1,
            borderTopWidth:1,
            borderBottomWidth:1,
            justifyContent: 'center',
            alignItems: 'center'
        },
        tbody:{ 
            fontSize : 9, 
            paddingTop: 4 , 
            paddingHorizontal: 2,
            flex:1, 
            borderColor : 'black', 
            borderRightWidth:1, 
            borderBottomWidth:1,
            justifyContent: 'center',
            alignItems: 'center'
        },
        
    });

    const InvoiceTitle = () => (
        <View style={{flexDirection: 'row', marginTop: 24, borderBottom: 2, marginBottom: 6, borderColor: '#CE3A78'}}>
            <View style={{flex : 1, flexDirection: 'row', alignItems:'center',justifyContent:'center', color: "#3E3E3E" }}>
                <Image style={{ width: 90 }} src={logo} />
                <View>
                        <View style={{flexDirection: 'row'}}>
                            <Text style={{  fontSize: 28,  textAlign: 'center', color: "#CE3A78", fontWeight: "extrabold" }}>PRIME</Text><Text style={{  fontSize: 28,  textAlign: 'center' , fontWeight: "extrabold" }}> LOGISTIC  </Text><Text style={{  fontSize: 28,  textAlign: 'center', color: "#3683EA", fontWeight: "extrabold"  }}>SERVICES</Text>
                        </View>
                        <View>
                            <Text style={{  fontSize: 10,  textAlign: 'center' }}>
                            7/1 Old F.D.C Road, Janata Bank Building (3rd Floor), Rail Gate, Tejgaon, Dhaka-1208
                            </Text>
                        </View>
                        <View style={{flexDirection: 'row', justifyContent:'space-between'}}>
                            <Text style={{  fontSize: 10,  textAlign: 'center' }}>
                                Ph: 8170191, 9132200 Cell: 01715 040692
                            </Text>
                            <Text style={{  fontSize: 10,  textAlign: 'center' }}>
                                Email: primelogistic2010@gmail.com
                            </Text>
                        </View>
                </View>
            </View>
        </View>
    );

    const Address = () => (
      <View style={{ marginTop: 10, marginHorizontal: 40 }}>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Text>No. {billOrder?.id}</Text>
          <Text>BILL</Text>
          <Text>Date: {moment(billOrder?.date).format("DD-MM-YYYY")}</Text>
        </View>
        <View
          style={{
            marginTop: 10,
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <View>
            <Text>{billOrder?.company?.name}</Text>
            <Text>{billOrder?.company?.address}</Text>
          </View>
          <View>
            {billOrder?.ref && <Text>Ref: {billOrder?.ref}</Text>}
            {billOrder?.po && <Text>PO No: {billOrder?.po}</Text>}
          </View>
        </View>
      </View>
    );

    const TableHead = () => (
      <View style={{ width: "100%", flexDirection: "row", marginTop: 10 }}>
        <View style={{ ...styles.theader, flex: 0.5, borderLeftWidth: 1, borderLeftColor: "black" }}>
          <Text>SL</Text>
        </View>
        <View style={{ ...styles.theader, flex: 1.5 }}>
          <Text>Date</Text>
        </View>
        <View style={{ ...styles.theader, flex: 1.5 }}>
          <Text>Challan No</Text>
        </View>
        <View style={{ ...styles.theader, flex: 2 }}>
          <Text>Truck No</Text>
        </View>
        {billOrder?.is_mawb ? (
          <View style={{ ...styles.theader, flex: 2 }}>
            <Text>Mawb No</Text>
          </View>
        ) : null}
        <View style={{ ...styles.theader, flex: 1 }}>
          <Text>Over</Text>
        </View>
        <View style={{ ...styles.theader, flex: 1.5 }}>
          <Text>Demurrage</Text>
        </View>
        <View style={{ ...styles.theader, flex: 1 }}>
          <Text>Parking</Text>
        </View>
        <View style={{ ...styles.theader, flex: 1 }}>
          <Text>Rent</Text>
        </View>
        <View style={{ ...styles.theader, flex: 1.5 }}>
          <Text>Total</Text>
        </View>
        {!billOrder?.is_mawb ? (
          <View style={{ ...styles.theader, flex: 2 }}>
            <Text>Remarks</Text>
          </View>
        ) : null}
      </View>
    );

    const TableBody = () => {
      var gap = false;
      return bills?.map((receipt, index) => {
        if (
          index > Math.floor(index / 34) * 34 + 18 &&
          bills.length < Math.floor(index / 34) * 34 + 30 &&
          index == bills.length - 2
        ) {
          gap = true;
        } else {
          gap = false;
        }
        return (
          <Fragment key={receipt.id}>
            <View style={{ width: "100%", flexDirection: "row" }}>
              <View style={{ ...styles.tbody, flex: 0.5, borderLeftWidth: 1, borderLeftColor: "black" }}>
                <Text>{index + 1}</Text>
              </View>
              <View style={{ ...styles.tbody, flex: 1.5 }}>
                <Text>{moment(receipt.date).format("DD MMM YY")}</Text>
              </View>
              <View style={{ ...styles.tbody, flex: 1.5 }}>
                <Text>{receipt.challan_no} </Text>
              </View>
              <View style={{ ...styles.tbody, flex: 2 }}>
                <Text>{receipt.truck_no}</Text>
              </View>

              {billOrder?.is_mawb ? (
                <View style={{ ...styles.tbody, flex: 2 }}>
                  <Text>{receipt.mawb_no}</Text>
                </View>
              ) : null}
              <View style={{ ...styles.tbody, flex: 1 }}>
                <Text>{receipt.over_hrs}</Text>
              </View>
              <View
                style={{
                  ...styles.tbody,
                  flex: 1.5,
                  alignItems: "flex-end",
                }}
              >
                <Text>{receipt.demurrage}</Text>
              </View>
              <View
                style={{ ...styles.tbody, flex: 1, alignItems: "flex-end" }}
              >
                <Text>{receipt.parking}</Text>
              </View>
              <View
                style={{ ...styles.tbody, flex: 1, alignItems: "flex-end" }}
              >
                <Text>{receipt.rent}</Text>
              </View>
              <View
                style={{
                  ...styles.tbody,
                  flex: 1.5,
                  alignItems: "flex-end",
                }}
              >
                <Text>
                  {(
                    parseFloat(receipt?.demurrage) +
                    parseFloat(receipt?.parking) +
                    parseFloat(receipt?.rent)
                  ).toFixed(0)}
                </Text>
              </View>
              {!billOrder?.is_mawb ? (
                <View style={{ ...styles.tbody, flex: 2 }}>
                  <Text>{receipt.mawb_no}</Text>
                </View>
              ) : null}
            </View>
            {gap && (
              <View>
                <View
                  style={{
                    width: "100%",
                    height: (28 - (index % 34)) * 20,
                  }}
                ></View>
                <InvoiceTitle />
              </View>
            )}
            {index % 34 == 27 && <InvoiceTitle />}
          </Fragment>
        );
      });
    };

    const TableTotal = () => (
      <View>
        <View style={{ width: "100%", flexDirection: "row" }}>
          <View style={{ ...styles.tbody, flex: 0.5, borderLeftWidth: 1, borderLeftColor: "black" }}></View>
          <View style={{ ...styles.tbody, flex: 1.5 }}></View>
          <View style={{ ...styles.tbody, flex: 1.5 }}></View>
          {billOrder?.is_mawb ? (
            <View style={{ ...styles.tbody, flex: 2 }}></View>
          ) : null}
          <View style={{ ...styles.tbody, flex: 2, alignItems: "flex-end" }}>
            <Text>Total</Text>
          </View>
          <View style={{ ...styles.tbody, flex: 1, alignItems: "center" }}>
            <Text>
              {bills
                .reduce((a, b) => a + parseFloat(b?.over_hrs), 0)
                .toFixed(0)}
            </Text>
          </View>
          <View style={{ ...styles.tbody, flex: 1.5, alignItems: "flex-end" }}>
            <Text>
              {bills
                .reduce((a, b) => a + parseFloat(b?.demurrage), 0)
                .toFixed(0)}
            </Text>
          </View>
          <View style={{ ...styles.tbody, flex: 1, alignItems: "flex-end" }}>
            <Text>
              {bills.reduce((a, b) => a + parseFloat(b?.parking), 0).toFixed(0)}
            </Text>
          </View>
          <View style={{ ...styles.tbody, flex: 1, alignItems: "flex-end" }}>
            <Text>
              {bills.reduce((a, b) => a + parseFloat(b?.rent), 0).toFixed(0)}
            </Text>
          </View>
          <View style={{ ...styles.tbody, flex: 1.5, alignItems: "flex-end" }}>
            <Text>
              {bills
                .reduce(
                  (a, b) =>
                    a +
                    parseFloat(b?.demurrage) +
                    parseFloat(b?.parking) +
                    parseFloat(b?.rent),
                  0
                )
                .toFixed(0)}
            </Text>
          </View>
          {!billOrder?.is_mawb ? (
            <View style={{ ...styles.tbody, flex: 2 }}></View>
          ) : null}
        </View>
        <View style={{ width: "100%", flexDirection: "row" }}>
          <View style={{ ...styles.tbody, flex: 0.5, borderLeftWidth: 1, borderLeftColor: "black" }}></View>
          <View style={{ ...styles.tbody, flex: 1.5 }}></View>
          <View style={{ ...styles.tbody, flex: 1.5 }}></View>

          {billOrder?.is_mawb ? (
            <View style={{ ...styles.tbody, flex: 2 }}></View>
          ) : null}
          <View style={{ ...styles.tbody, flex: 2, alignItems: "flex-end" }}>
            <Text>VAT</Text>
          </View>
          <View style={{ ...styles.tbody, flex: 1 }}></View>
          <View style={{ ...styles.tbody, flex: 1.5, alignItems: "flex-end" }}>
            <Text>{billOrder?.vat}%</Text>
          </View>
          <View style={{ ...styles.tbody, flex: 1 }}></View>
          <View style={{ ...styles.tbody, flex: 1 }}></View>
          <View style={{ ...styles.tbody, flex: 1.5, alignItems: "flex-end" }}>
            <Text>
              {(
                bills
                  .reduce(
                    (a, b) =>
                      a +
                      parseFloat(b?.demurrage) +
                      parseFloat(b?.parking) +
                      parseFloat(b?.rent),
                    0
                  )
                  .toFixed(0) *
                (billOrder?.vat / 100)
              ).toFixed(0)}
            </Text>
          </View>
          {!billOrder?.is_mawb ? (
            <View style={{ ...styles.tbody, flex: 2 }}></View>
          ) : null}
        </View>
        <View style={{ width: "100%", flexDirection: "row" }}>
          <View style={{ ...styles.tbody, flex: 0.5, borderLeftWidth: 1, borderLeftColor: "black" }}></View>
          <View style={{ ...styles.tbody, flex: 1.5 }}></View>
          <View style={{ ...styles.tbody, flex: 1.5 }}></View>

          {billOrder?.is_mawb ? (
            <View style={{ ...styles.tbody, flex: 2 }}></View>
          ) : null}
          <View style={{ ...styles.tbody, flex: 2, alignItems: "flex-end" }}>
            <Text>Grand Total</Text>
          </View>
          <View style={{ ...styles.tbody, flex: 1 }}></View>
          <View style={{ ...styles.tbody, flex: 1.5 }}></View>
          <View style={{ ...styles.tbody, flex: 1 }}></View>
          <View style={{ ...styles.tbody, flex: 1 }}></View>
          <View style={{ ...styles.tbody, flex: 1.5, alignItems: "flex-end" }}>
            <Text>
              {(
                bills
                  .reduce(
                    (a, b) =>
                      a +
                      parseFloat(b?.demurrage) +
                      parseFloat(b?.parking) +
                      parseFloat(b?.rent),
                    0
                  )
                  .toFixed(0) *
                (billOrder?.vat / 100 + 1)
              ).toFixed(0)}
            </Text>
          </View>

          {!billOrder?.is_mawb ? (
            <View style={{ ...styles.tbody, flex: 2 }}></View>
          ) : null}
        </View>
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            marginHorizontal: 40,
            marginTop: 10,
          }}
        >
          <Text style={{ marginRight: 10 }}>In Words:</Text>
          <Text>
            {inWords(
              (
                bills
                  .reduce(
                    (a, b) =>
                      a +
                      parseFloat(b?.demurrage) +
                      parseFloat(b?.parking) +
                      parseFloat(b?.rent),
                    0
                  )
                  .toFixed(0) *
                (billOrder?.vat / 100 + 1)
              ).toFixed(0)
            )}{" "}
            taka only.
          </Text>
        </View>
      </View>
    );

    const Footer = () => (
        <View style={{ marginTop:50, marginRight:30, justifyContent: 'flex-end', alignItems: "flex-end"}}>
            <Text style={{borderBottom: 1, borderColor: '#000'}}>MD. Shahin Mridha</Text>
            <Text style={{textAlign:'center', marginRight: 8}}>PROPRIETOR</Text>
        </View>
    );

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <InvoiceTitle  />
                <Address/>
                <TableHead/>
                <TableBody/>
                <TableTotal/>
                <Footer/>
            </Page>
        </Document>
          
    )
}

export default PrintBill;