import Modal from "components/utils/Modal";
import { useState } from "react";
import CompanyService from "services/CompanyService";
import React from "react";

const CreateCompany = ({ open, onCloseModal, onCreated }) => {
  const [data, setData] = useState({});

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setData({
      ...data,
      [name]: value,
    });
  };

  const createCompany = async () => {
    await CompanyService.create(data);
    setData({});
    onCreated();
    onCloseModal();
  };

  return (
    <div>
      <Modal
        size="xl"
        open={open}
        onCloseModal={onCloseModal}
        title={<>Add Company</>}
        body={
          <>
            <form id="create-company">
              <div className="row">
              <div className="col-md-6 mb-5 fv-row fv-plugins-icon-container">
                <label className="required form-label">Company Name</label>
                <input
                  type="text"
                  className="form-control mb-2"
                  placeholder="Company Name"
                  name="name"
                  value={data?.name}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6 mb-5 fv-row fv-plugins-icon-container">
                <label className="required form-label">BIN</label>
                <input
                  type="text"
                  className="form-control mb-2"
                  placeholder="Company BIN"
                  name="bin"
                  value={data?.bin}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-5 fv-row fv-plugins-icon-container">
                <label className="form-label">Address</label>
                <textarea
                  rows="3"
                  type="text"
                  className="form-control"
                  placeholder="Company Address"
                  name="address"
                  value={data?.address}
                  onChange={handleChange}
                />
              </div>
                <div className="col-md-6">
                  <div className="mb-5 fv-row fv-plugins-icon-container">
                    <label className="form-label">Owner Name</label>
                    <input
                      type="text"
                      className="form-control mb-2"
                      placeholder="Enter Owner Name"
                      name="owner_name"
                      value={data?.owner_name}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="mb-5 fv-row fv-plugins-icon-container">
                    <label className="form-label">Owner Email</label>
                    <input
                      type="text"
                      className="form-control mb-2"
                      placeholder="Enter Owner Email"
                      name="owner_email"
                      value={data?.owner_email}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="mb-5 fv-row fv-plugins-icon-container">
                    <label className="form-label">Owner Phone</label>
                    <input
                      type="text"
                      className="form-control mb-2"
                      placeholder="Enter Owner Phone number"
                      name="owner_phone"
                      value={data?.owner_phone}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-5 fv-row fv-plugins-icon-container">
                    <label className="form-label">Contact PersonName</label>
                    <input
                      type="text"
                      className="form-control mb-2"
                      placeholder="Enter Contact PersonName"
                      name="contact_person_name"
                      value={data?.contact_person_name}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="mb-5 fv-row fv-plugins-icon-container">
                    <label className="form-label">Contact PersonEmail</label>
                    <input
                      type="text"
                      className="form-control mb-2"
                      placeholder="Enter Contact PersonEmail"
                      name="contact_person_email"
                      value={data?.contact_person_email}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="mb-5 fv-row fv-plugins-icon-container">
                    <label className="form-label">Contact PersonPhone</label>
                    <input
                      type="text"
                      className="form-control mb-2"
                      placeholder="Enter Contact PersonPhone number"
                      name="contact_person_phone"
                      value={data?.contact_person_phone}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              

              <button
                type="reset"
                className="btn btn-primary mr-2 mt-5"
                style={{ marginRight: "1rem" }}
                onClick={createCompany}
              >
                Submit
              </button>
              <button
                type="reset"
                className="btn btn-secondary  mt-5 "
                onClick={onCloseModal}
              >
                Cancel
              </button>
            </form>
          </>
        }
      />
    </div>
  );
};

export default CreateCompany;
