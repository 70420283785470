import { PrivateRoute } from "helpers/PrivateRoute";
import Trucks from "pages/trucks/Index";
import { Route } from "react-router-dom";
import React from "react";

const index = () => {
    return (<>
      <Route
        path="trucks"
        element={
          <PrivateRoute>
            <Trucks />
          </PrivateRoute>
        }
      />
    </>);
}

export default index;