import Confirmation from "components/utils/Confirmation";
import Table from "components/utils/Table";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TruckService from "services/TruckService";
import Create from "./Create";
import Edit from "./Edit";
import React from "react";

const Companies = () => {
  const [loading, setLoading] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [data, setData] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const columns = [
    {
      name: "Number",
      selector: (row) => row?.truck_no,
      format: (row) => (
        <div className="d-flex align-items-center">
          <div className="symbol symbol-50px me-5">
            {row?.truck_no}
          </div>
        </div>
      ),
    },
    {
      name: "Tax Token Expiry",
      selector: (row) => row?.tax_token_expiry,
      format: (row) => (
        <span>
          {new Date(row?.tax_token_expiry) < new Date() ? (
            <span className="text-danger">{moment(row?.tax_token_expiry).format("DD MMM YYYY")}</span>
          ) : new Date(row?.tax_token_expiry) < new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000) ? (
            <span className="text-warning">{moment(row?.tax_token_expiry).format("DD MMM YYYY")}</span>
          ) : (
            <span className="text-success">{moment(row?.tax_token_expiry).format("DD MMM YYYY")}</span>
          )}
        </span>
      ),
    },
    {
      name: "Fitness Expiry",
      selector: (row) => row?.fitness_expiry,
      format: (row) => (
        <span>
          {new Date(row?.fitness_expiry) < new Date() ? (
            <span className="text-danger">{moment(row?.fitness_expiry).format("DD MMM YYYY")}</span>
          ) :new Date(row?.fitness_expiry) < new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000) ? (
            <span className="text-warning">{moment(row?.fitness_expiry).format("DD MMM YYYY")}</span>
          ) : (
            <span className="text-success">{moment(row?.fitness_expiry).format("DD MMM YYYY")}</span>
          )}
        </span>
      ),
    },
    {
      name: "Insurance Expiry",
      selector: (row) => row?.insurance_expiry,
      format: (row) => (
        <span>
          {new Date(row?.insurance_expiry) < new Date() ? (
            <span className="text-danger">{moment(row?.insurance_expiry).format("DD MMM YYYY")}</span>
          ) :new Date(row?.insurance_expiry) < new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000) ? (
            <span className="text-warning">{moment(row?.insurance_expiry).format("DD MMM YYYY")}</span>
          ) : (
            <span className="text-success">{moment(row?.insurance_expiry).format("DD MMM YYYY")}</span>
          )}
        </span>
      ),
    },
    {
      name: "Permit Expiry",
      selector: (row) => row?.permit_expiry,
      format: (row) => (
        <span>
          {new Date(row?.permit_expiry) < new Date() ? (
            <span className="text-danger">{moment(row?.permit_expiry).format("DD MMM YYYY")}</span>
          ) : new Date(row?.permit_expiry) < new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000) ? (
            <span className="text-warning">{moment(row?.permit_expiry).format("DD MMM YYYY")}</span>
          ) : (
            <span className="text-success">{moment(row?.permit_expiry).format("DD MMM YYYY")}</span>
          )}
        </span>
      ),
    },
    {
      name: "Action",
      selector: (row) => row.status,
      format: (row) => (
        <span className="text-end">
            <button
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
              onClick={() => {
                setSelectedId(row?.id);
                setOpenEditModal(true);
              }}
            >
              <i className="fa fa-pen"></i>
            </button>
            <Link
              to="#"
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
              onClick={() => {
                setSelectedId(row?.id);
                setConfirmDelete(true);
              }}
            >
              <i className="fa fa-trash"></i>
            </Link>
        </span>
      ),
    },
  ];

  const getData = async (filters) => {
    setData(await TruckService.getAll(filters));
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);

  const deletePart = async (selectedId) => {
    await TruckService.remove(selectedId);
    getData();
  };

  const onCloseModal = () => {
    setOpenAddModal(false);
    setOpenEditModal(false);
  };

  return (
    <>
      <div className="post d-flex flex-column-fluid">
        <div className="container-xxl">
          <Table
            name="by Truck number"
            buttonName="Add Truck"
            onClickButton={() => setOpenAddModal(true)}
            isLoading={loading}
            data={data}
            columns={columns}
            onFilter={getData}
          />
        </div>
      </div>

      <Confirmation
        open={confirmDelete}
        onConfirm={() => {
          setConfirmDelete(false);
          deletePart(selectedId);
        }}
        onCancel={() => setConfirmDelete(false)}
      /> 
      <Create
        open={openAddModal}
        onCloseModal={onCloseModal}
        onCreated={getData}
      />
      <Edit
        open={openEditModal}
        selectedId={selectedId}
        onCloseModal={onCloseModal}
        onUpdated={getData}
      />
    </>
  );
};

export default Companies;
