import { BlobProvider, PDFDownloadLink } from "@react-pdf/renderer";
import Confirmation from "components/utils/Confirmation";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import BillOrderService from "services/BillOrderService";
import BillService from "services/BillService";
import PrintBill from "./PrintBill";
import PrintVAT from "./PrintVAT";
import React from "react";
import EditBillData from "./Edit";


const Index = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  
  const [data, setData] = useState({});
  const [invoiceNo, setInvoiceNo] = useState();
  const [invoiceDate, setInvoiceDate] = useState(new Date());
  const [openEditModal, setOpenEditModal] = useState(false);

  const getData = async () => {
    const res = await BillOrderService.get(id);
    setData(res);
  };

  const [billsData, setBillsData] = useState([]);

  const getBillsData = async (params) => {
    const res = await BillService.getAll(id, params);
    setBillsData(res);
  };

  useEffect(() => {
    getData();
    getBillsData();
  }, [id]);


  const [billDetails, setBillDetails] = useState({
    default_demurrage: 200,
    date: moment(new Date()).format('YYYY-MM-DD'),
    over_hrs: 0,
    parking: 0,
    rent: 0,
    demurrage: 0,
    default_rent: 0,
  });
  console.log(billDetails);

  useEffect(() => {
    setBillDetails({
      ...billDetails,
      demurrage: billDetails.default_demurrage * billDetails.over_hrs,
    });
  }, [billDetails.default_demurrage, billDetails.over_hrs]);

  useEffect(() => {
    setBillDetails({
      ...billDetails,
      rent: billDetails.default_rent,
    });
  }, [billDetails.default_rent]);

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setBillDetails({
      ...billDetails,
      [name]: value,
    });
  }

  const createBill = async () => {
    await BillService.create(id, billDetails);
    setBillDetails({
      default_demurrage: billDetails.default_demurrage,
      date: billDetails.date,
      truck_no: '',
      challan_no: '',
      mawb_no: '',
      over_hrs: 0,
      demurrage: 0,
      parking: 0,
      default_rent: billDetails.default_rent,
      rent: billDetails.default_rent,
    });
    getBillsData();
  }

  const [billId, setbillId] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const deleteBill = async (billId) => {
    await BillService.remove(billId);
    getBillsData();
  };

  return (
    <div className="post d-flex flex-column-fluid mt-5">
      <div className="container-xxl">
        <div className="form d-flex flex-column flex-lg-row gap-7 gap-lg-10 fv-plugins-bootstrap5 fv-plugins-framework">
          <div className="d-flex flex-column flex-row-fluid gap-5 gap-lg-5">
            <div className="card p-5">
              <div className="row">
                <div className="col-md-3">
                  <h3 className="card-label">
                    <button
                      className="btn btn-sm btn-dark "
                      style={{ marginRight: "0.75rem" }}
                      onClick={() => navigate(-1)}
                    >
                      <i className="fa fa-arrow-left"></i>Back
                    </button>
                    Bill Details
                  </h3>
                </div>

                <div className="col-md-2">
                  <input
                    type="input"
                    className="form-control"
                    placeholder="Enter Invoice No"
                    value={invoiceNo}
                    onChange={(e) => setInvoiceNo(e.target.value)}
                  />
                </div>

                <div className="col-md-2">
                  <input
                    type="date"
                    className="form-control"
                    value={invoiceDate}
                    onChange={(e) => setInvoiceDate(e.target.value)}
                  />
                </div>

                <div className="col-md-2 d-flex justify-content-end">
                  <div className="p-3 me-2">VAT Copy</div>
                  <PDFDownloadLink
                    document={
                      <PrintVAT
                        billOrder={data}
                        bills={billsData}
                        invoiceNo={invoiceNo}
                        invoiceDate={invoiceDate}
                      />
                    }
                    fileName={
                      "vat_" + data?.id + "_" + data?.company?.name + ".pdf"
                    }
                  >
                    <div className="p-3 border border-2 border-dark rounded me-2">
                      <i className="fa fa-download"></i>
                    </div>
                  </PDFDownloadLink>

                  <BlobProvider
                    document={
                      <PrintVAT
                        billOrder={data}
                        bills={billsData}
                        invoiceNo={invoiceNo}
                        invoiceDate={invoiceDate}
                      />
                    }
                  >
                    {({ url, blob }) => {
                      console.log(blob);
                      return (
                        <div className="p-3 border border-2 border-dark rounded">
                          <a href={url} target="_blank" rel="noreferrer">
                            <i className="fa fa-print"></i>
                          </a>
                        </div>
                      );
                    }}
                  </BlobProvider>
                </div>
                <div className="col-md-3 d-flex justify-content-end">
                  <div className="p-3 me-2">Bill Copy</div>
                  <PDFDownloadLink
                    document={<PrintBill billOrder={data} bills={billsData} />}
                    fileName={
                      "bill_" + data?.id + "_" + data?.company?.name + ".pdf"
                    }
                  >
                    <div className="p-3 border border-2 border-dark rounded me-2">
                      <i className="fa fa-download"></i>
                    </div>
                  </PDFDownloadLink>

                  <BlobProvider
                    document={<PrintBill billOrder={data} bills={billsData} />}
                  >
                    {({ url, blob }) => {
                      console.log(blob);
                      return (
                        <div className="p-3 border border-2 border-dark rounded">
                          <a href={url} target="_blank" rel="noreferrer">
                            <i className="fa fa-print"></i>
                          </a>
                        </div>
                      );
                    }}
                  </BlobProvider>
                </div>
              </div>

              <div className="collapse show text-left">
                <div className="row">
                  <div className="col-md-3">
                    <div className="fw-bolder">Company Name</div>
                    <div className="text-gray-600">
                      {data.company?.name}({data.company?.bin})
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="fw-bolder">Date</div>
                    <div className="text-gray-600">
                      {moment(data.date).format("DD-MMM-YYYY")}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="fw-bolder">VAT</div>
                    <div className="text-gray-600">{data.vat}%</div>
                  </div>
                  <div className="col-md-3"></div>
                  <div className="col-md-3">
                    <div className="fw-bolder">Default demurrage per hour</div>
                    <input
                      type="number"
                      className="form-control mb-2"
                      placeholder="Enter Default demurrage per hour"
                      name="default_demurrage"
                      value={billDetails?.default_demurrage}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-3">
                    <div className="fw-bolder">Default Rent</div>
                    <input
                      type="number"
                      className="form-control mb-2"
                      placeholder="Enter Default demurrage per hour"
                      name="default_rent"
                      value={billDetails?.default_rent}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-header pt-5">
              <div className="collapse show text-left">
                <div className="row">
                  <div className="col-md-3">
                    <div className="fw-bolder ">Date</div>
                    <input
                      type="date"
                      className="form-control mb-2"
                      placeholder="Enter Date"
                      name="date"
                      value={billDetails?.date}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-3">
                    <div className="fw-bolder">Challan No</div>
                    <input
                      type="text"
                      className="form-control mb-2"
                      placeholder="Enter Challan No"
                      name="challan_no"
                      value={billDetails?.challan_no}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-3">
                    <div className="fw-bolder">Truck No</div>
                    <input
                      type="text"
                      className="form-control mb-2"
                      placeholder="Enter Truck No"
                      name="truck_no"
                      value={billDetails?.truck_no}
                      onChange={handleChange}
                    />
                  </div>
                  {data.is_mawb ? (
                    <div className="col-md-3">
                      <div className="fw-bolder">Mawb no</div>
                      <input
                        type="text"
                        className="form-control mb-2"
                        placeholder="Enter Mawb no"
                        name="mawb_no"
                        value={billDetails?.mawb_no}
                        onChange={handleChange}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="col-md-3">
                    <div className="fw-bolder">over(hrs)</div>
                    <input
                      type="number"
                      className="form-control mb-2"
                      placeholder="Enter over(hrs)"
                      name="over_hrs"
                      value={billDetails?.over_hrs}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-3">
                    <div className="fw-bolder">Demurrage</div>
                    <input
                      type="number"
                      className="form-control mb-2"
                      placeholder="Enter Demurrage"
                      name="demurrage"
                      value={billDetails?.demurrage}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-3">
                    <div className="fw-bolder">Parking</div>
                    <input
                      type="number"
                      className="form-control mb-2"
                      placeholder="Enter Parking"
                      name="parking"
                      value={billDetails?.parking}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-3">
                    <div className="fw-bolder">Rent</div>
                    <input
                      type="number"
                      className="form-control mb-2"
                      placeholder="Enter Rent"
                      name="rent"
                      value={billDetails?.rent}
                      onChange={handleChange}
                    />
                  </div>
                  {!data.is_mawb ? (
                    <div className="col-md-3">
                      <div className="fw-bolder">Remarks</div>
                      <input
                        type="text"
                        className="form-control mb-2"
                        placeholder="Enter Remarks"
                        name="mawb_no"
                        value={billDetails?.mawb_no}
                        onChange={handleChange}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <button
                  type="submit"
                  className="btn btn-primary mr-2 mt-5"
                  style={{ marginRight: "1rem" }}
                  onClick={createBill}
                >
                  Add a new Bill
                </button>
              </div>
            </div>

            <div className="card-header pt-5">
              <div className="row">
                <div className="col-md-1 d-flex justify-content-center">
                  <div className="fw-bolder">S.N</div>
                </div>
                <div className="col-md-1 d-flex justify-content-center">
                  <div className="fw-bolder">Date</div>
                </div>
                <div className="col-md-1 d-flex justify-content-center">
                  <div className="fw-bolder">Challan No</div>
                </div>
                <div className="col-md-1 d-flex justify-content-center">
                  <div className="fw-bolder">Truck No</div>
                </div>
                {data?.is_mawb ? (
                  <div className="col-md-2 d-flex justify-content-center">
                    <div className="fw-bolder">Mawb No</div>
                  </div>
                ) : (
                  ""
                )}
                <div className="col-md-1 d-flex justify-content-center">
                  <div className="fw-bolder">Over</div>
                </div>
                <div className="col-md-1 d-flex justify-content-center">
                  <div className="fw-bolder">Demurrage</div>
                </div>
                <div className="col-md-1 d-flex justify-content-center">
                  <div className="fw-bolder">Parking</div>
                </div>
                <div className="col-md-1 d-flex justify-content-center">
                  <div className="fw-bolder">Rent</div>
                </div>
                <div className="col-md-1 d-flex justify-content-center">
                  <div className="fw-bolder">Total</div>
                </div>
                {!data?.is_mawb ? (
                  <div className="col-md-2 d-flex justify-content-center">
                    <div className="fw-bolder">Remarks</div>
                  </div>
                ) : (
                  ""
                )}
                <div className="col-md-1 d-flex justify-content-center">
                  <div className="fw-bolder">Action</div>
                </div>
              </div>
              {billsData?.map((bill, index) => (
                <div className="row border-top py-1" key={index}>
                  <div className="col-md-1 d-flex justify-content-center align-items-center">
                    <div className="text-gray-600">{index + 1}</div>
                  </div>
                  <div className="col-md-1 d-flex justify-content-center align-items-center">
                    <div className="text-gray-600">{bill?.date}</div>
                  </div>
                  <div className="col-md-1 d-flex justify-content-center align-items-center">
                    <div className="text-gray-600">{bill?.challan_no}</div>
                  </div>
                  <div className="col-md-1 d-flex justify-content-center align-items-center">
                    <div className="text-gray-600">{bill?.truck_no}</div>
                  </div>
                  {data?.is_mawb ? (<div className="col-md-2 d-flex justify-content-center align-items-center">
                    <div className="text-gray-600">{bill?.mawb_no}</div>
                  </div>
                  ) : (
                    ""
                  )}
                  <div className="col-md-1 d-flex justify-content-center align-items-center">
                    <div className="text-gray-600">{bill?.over_hrs}</div>
                  </div>
                  <div className="col-md-1 d-flex justify-content-center align-items-center">
                    <div className="text-gray-600">{bill?.demurrage}</div>
                  </div>
                  <div className="col-md-1 d-flex justify-content-center align-items-center">
                    <div className="text-gray-600">{bill?.parking}</div>
                  </div>
                  <div className="col-md-1 d-flex justify-content-center align-items-center">
                    <div className="text-gray-600">{bill?.rent}</div>
                  </div>
                  <div className="col-md-1 d-flex justify-content-center align-items-center">
                    <div className="text-gray-600">
                      {(
                        parseFloat(bill?.demurrage) +
                        parseFloat(bill?.parking) +
                        parseFloat(bill?.rent)
                      ).toFixed(0)}
                    </div>
                  </div>
                  {!data?.is_mawb ? (<div className="col-md-2 d-flex justify-content-center align-items-center">
                    <div className="text-gray-600">{bill?.mawb_no}</div>
                  </div>
                  ) : (
                    ""
                  )}
                  <div className="col-md-1 d-flex justify-content-center align-items-center">
                    <Link
                      to="#"
                      className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                      onClick={() => {
                        setbillId(bill?.id);
                        setOpenEditModal(true);
                      }}
                    >
                      <i className="fa fa-pen"></i>
                    </Link>
                    <Link
                      to="#"
                      className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                      onClick={() => {
                        setbillId(bill?.id);
                        setConfirmDelete(true);
                      }}
                    >
                      <i className="fa fa-trash"></i>
                    </Link>
                  </div>
                </div>
              ))}
              <div className="row border-top pt-2">
                <div className="col-md-4 d-flex justify-content-center">
                  <div className="fw-bolder">Total</div>
                </div>
                {data?.is_mawb ? <div className="col-md-2"></div> : ""}
                <div className="col-md-1 d-flex justify-content-center">
                  <div className="fw-bolder">
                    {billsData.reduce((a, b) => a + parseFloat(b?.over_hrs), 0)}
                  </div>
                </div>
                <div className="col-md-1 d-flex justify-content-center">
                  <div className="fw-bolder">
                    {billsData
                      .reduce((a, b) => a + parseFloat(b?.demurrage), 0)
                      .toFixed(0)}
                  </div>
                </div>
                <div className="col-md-1 d-flex justify-content-center">
                  <div className="fw-bolder">
                    {billsData
                      .reduce((a, b) => a + parseFloat(b?.parking), 0)
                      .toFixed(0)}
                  </div>
                </div>
                <div className="col-md-1 d-flex justify-content-center">
                  <div className="fw-bolder">
                    {billsData
                      .reduce((a, b) => a + parseFloat(b?.rent), 0)
                      .toFixed(0)}
                  </div>
                </div>
                <div className="col-md-1 d-flex justify-content-center">
                  <div className="fw-bolder">
                    {billsData
                      .reduce(
                        (a, b) =>
                          a +
                          parseFloat(b?.demurrage) +
                          parseFloat(b?.parking) +
                          parseFloat(b?.rent),
                        0
                      )
                      .toFixed(0)}
                  </div>
                </div>
                <div className="col-md-1 d-flex justify-content-center"></div>
              </div>
              <div className="row">
                <div className="col-md-6 d-flex justify-content-center"></div>
                {data?.is_mawb ? <div className="col-md-2"></div> : ""}
                <div className="col-md-1 d-flex justify-content-center">
                  <div className="fw-bolder">VAT</div>
                </div>
                <div className="col-md-1 d-flex justify-content-center">
                  <div className="fw-bolder">{data.vat}%</div>
                </div>
                <div className="col-md-1 d-flex justify-content-center">
                  <div className="fw-bolder">
                    {(
                      (billsData.reduce(
                        (a, b) =>
                          a +
                          parseFloat(b?.demurrage) +
                          parseFloat(b?.parking) +
                          parseFloat(b?.rent),
                        0
                      ) *
                        data.vat) /
                      100
                    ).toFixed(0)}
                  </div>
                </div>
                <div className="col-md-1 d-flex justify-content-center"></div>
              </div>
              <div className="row">
                <div className="col-md-6 d-flex justify-content-center"></div>
                {data?.is_mawb ? <div className="col-md-2"></div> : ""}
                <div className="col-md-1 d-flex justify-content-center">
                  <div className="fw-bolder">Grand Total</div>
                </div>
                <div className="col-md-1 d-flex justify-content-center"></div>
                <div className="col-md-1 d-flex justify-content-center">
                  <div className="fw-bolder">
                    {(
                      billsData.reduce(
                        (a, b) =>
                          a +
                          parseFloat(b?.demurrage) +
                          parseFloat(b?.parking) +
                          parseFloat(b?.rent),
                        0
                      ) *
                      (data.vat / 100 + 1)
                    ).toFixed(0)}
                  </div>
                </div>
                <div className="col-md-1 d-flex justify-content-center"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditBillData
        open={openEditModal}
        id={billId}
        is_mawb={data.is_mawb}
        default_demurrage={billDetails.default_demurrage}
        onCloseModal={() => setOpenEditModal(false)}
        onUpdated={getBillsData}
      />
      <Confirmation
        open={confirmDelete}
        onConfirm={() => {
          setConfirmDelete(false);
          deleteBill(billId);
        }}
        onCancel={() => setConfirmDelete(false)}
      />
    </div>
  );
};

export default Index;
