import { PrivateRoute } from "helpers/PrivateRoute";
import BillOrders from "pages/bills/Index";
import ShowBillOrder from "pages/bills/Show/Index";
import { Route } from "react-router-dom";
import React from "react";

const index = () => {
    return (<>
      <Route
        path="bills"
        element={
          <PrivateRoute>
            <BillOrders />
          </PrivateRoute>
        }
      />
      <Route
        path="bills/:id"
        element={
          <PrivateRoute>
            <ShowBillOrder />
          </PrivateRoute>
        }
      />
    </>);
}

export default index;