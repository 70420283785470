import http from "../http-common";

const getAll = async (params) => {
  const res = await http.get("/bill-orders", {params});
  return res.data;
};

const get = async (id) => {
  const res = await http.get(`/bill-orders/${id}`);
  return res.data;
};

const create = async (data) => {
  return http.post("/bill-orders", data);
};

const update = async (id, data) => {
  const res = await http.put(`/bill-orders/${id}`, data);
  return res.data;
};

const remove = async (id) => {
  const res = await http.delete(`/bill-orders/${id}`);
  return res.data;
};

const BillOrderService = {
  getAll,
  get,
  create,
  update,
  remove,
};

export default BillOrderService;