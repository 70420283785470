import Confirmation from "components/utils/Confirmation";
import Table from "components/utils/Table";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CompanyService from "services/CompanyService";
import CreateCompany from "./Create";
import EditCompany from "./Edit";
import React from "react";

const Companies = () => {
  const [loading, setLoading] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [data, setData] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [companyId, setcompanyId] = useState(null);

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      format: (row) => (
        <div className="d-flex align-items-center">
          <div className="symbol symbol-50px me-5">
            <b>{row.name}</b> <br />
            {row.bin}
          </div>
        </div>
      ),
    },
    {
      name: "Owner",
      selector: (row) => row.owner_name,
      format: (row) => (
        <div className="d-flex align-items-center">
          <div className="symbol symbol-50px me-5">
          <div>{row.owner_name}</div>
          <div>{row.owner_phone}</div>
          <div>{row.owner_email}</div>
          </div>
        </div>
      ),
    },
    {
      name: "Contact Person",
      selector: (row) => row.contact_person_name,
      format: (row) => (
        <div className="d-flex align-items-center">
          <div className="symbol symbol-50px me-5">
          <div>{row.contact_person_name}</div>
          <div>{row.contact_person_phone}</div>
          <div>{row.contact_person_email}</div>
          </div>
        </div>
      ),
    },
    {
      name: "Action",
      selector: (row) => row.status,
      format: (row) => (
        <span className="text-end">
            <Link
              to={"/companies/" + row?.id}
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
            >
              <i className="fa fa-eye"></i>
            </Link>
            <button
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
              onClick={() => {
                setcompanyId(row?.id);
                setOpenEditModal(true);
              }}
            >
              <i className="fa fa-pen"></i>
            </button>
            <Link
              to="#"
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
              onClick={() => {
                setcompanyId(row?.id);
                setConfirmDelete(true);
              }}
            >
              <i className="fa fa-trash"></i>
            </Link>
        </span>
      ),
    },
  ];

  const getCompanies = async (filters) => {
    setData(await CompanyService.getAll(filters));
    setLoading(false);
  };
  useEffect(() => {
    getCompanies();
  }, []);

  const deletePart = async (companyId) => {
    await CompanyService.remove(companyId);
    getCompanies();
  };

  const onCloseModal = () => {
    setOpenAddModal(false);
    setOpenEditModal(false);
  };

  return (
    <>
      <div className="post d-flex flex-column-fluid">
        <div className="container-xxl">
          <Table
            name="Companies"
            buttonName="Add Company"
            onClickButton={() => setOpenAddModal(true)}
            isLoading={loading}
            data={data}
            columns={columns}
            onFilter={getCompanies}
          />
        </div>
      </div>

      <Confirmation
        open={confirmDelete}
        onConfirm={() => {
          setConfirmDelete(false);
          deletePart(companyId);
        }}
        onCancel={() => setConfirmDelete(false)}
      /> 
      <CreateCompany
        open={openAddModal}
        onCloseModal={onCloseModal}
        onCreated={getCompanies}
      />
      <EditCompany
        open={openEditModal}
        companyId={companyId}
        onCloseModal={onCloseModal}
        onUpdated={getCompanies}
      />
    </>
  );
};

export default Companies;
