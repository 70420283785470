import Confirmation from "components/utils/Confirmation";
import Table from "components/utils/Table";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TripService from "services/TripService";
import Create from "./Create";
import Edit from "./Edit";
import Filter from "./Filter";
import React from "react";

const Companies = () => {
  const [loading, setLoading] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [data, setData] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [enableFilter, setEnableFilter] = useState(false);

  const columns = [
    {
      name: "Truck Number",
      selector: (row) => row?.date,
      format: (row) => (
        <div className="d-flex align-items-center">
          <div className="symbol symbol-50px me-5">
            <b>{row?.truck?.truck_no}</b><br />
            {moment(row?.date).format("DD MMM YYYY")} 
          </div>
        </div>
      ),
    },
    {
      name: "Driver",
      selector: (row) => row?.driver_name,
      format: (row) => (
        <div>
          {row?.driver_name}<br />
          {row?.driver_phone}
        </div>
      ),
    },
    {
      name: "Company",
      selector: (row) => row?.company_name,
      format: (row) => (
        <div>
          {row?.company_name}<br />
          {row?.company_phone}
        </div>
      ),
    },
    {
      name: "Contact Person",
      selector: (row) => row?.company_contact_person,
      format: (row) => (
        <div>
          {row?.company_contact_person}<br />
          {row?.company_contact_person_phone}
        </div>
      ),
    },
    {
      name: "Load/Unload",
      selector: (row) => row?.loading_point,
      format: (row) => (
        <div>
          {row?.loading_point}<br />
          {row?.unloading_point}
        </div>
      ),
    },
    {
      name: "Rent",
      selector: (row) => row?.rent,
      format: (row) => (
        <div>{row?.rent} tk</div>
      ),
    },
    {
      name: "Status",
      selector: (row) => row?.rent,
      format: (row) => (
        <div>{row?.status}</div>
      ),
    },
    {
      name: "Action",
      selector: (row) => row.status,
      format: (row) => (
        <span className="text-end">
            <button
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
              onClick={() => {
                setSelectedId(row?.id);
                setOpenEditModal(true);
              }}
            >
              <i className="fa fa-pen"></i>
            </button>
            <Link
              to="#"
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
              onClick={() => {
                setSelectedId(row?.id);
                setConfirmDelete(true);
              }}
            >
              <i className="fa fa-trash"></i>
            </Link>
        </span>
      ),
    },
  ];

  const getData = async (filters) => {
    setData(await TripService.getAll(filters));
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);

  const deletePart = async (selectedId) => {
    await TripService.remove(selectedId);
    getData();
  };

  const onCloseModal = () => {
    setOpenAddModal(false);
    setOpenEditModal(false);
  };

  return (
    <>
      <div className="post d-flex flex-column-fluid">
        <div className="container-xxl">
          <Table
            name="by Truck number"
            buttonName="Add Trip"
            onClickButton={() => setOpenAddModal(true)}
            isLoading={loading}
            data={data}
            columns={columns}
            onFilter={getData}
            callbackButtons={[
              {
                name: "Filter",
                callback: () => {
                  setEnableFilter(!enableFilter);
                },
                permission: null,
              },
            ]}
          />
        </div>
      </div>

      <Confirmation
        open={confirmDelete}
        onConfirm={() => {
          setConfirmDelete(false);
          deletePart(selectedId);
        }}
        onCancel={() => setConfirmDelete(false)}
      /> 
      <Create
        open={openAddModal}
        onCloseModal={onCloseModal}
        onCreated={getData}
      />
      <Edit
        open={openEditModal}
        selectedId={selectedId}
        onCloseModal={onCloseModal}
        onUpdated={getData}
      />
      <Filter
        enable={enableFilter}
        onClickOutside={() => {
          setEnableFilter(!enableFilter);
        }}
        onChange={getData}
      />
    </>
  );
};

export default Companies;
