import { PrivateRoute } from "helpers/PrivateRoute";
import Login from "pages/auth/Login";
import Dashboard from "pages/dashboard/Dashboard";
import Forbidden from "pages/Forbidden";
import AppLayout from "pages/layouts/AppLayout";
import NotFound from "pages/NotFound";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import billsRoutes from "pages/bills/Route";
import companyRoutes from "pages/companies/Route";
import tripsRoutes from "pages/trips/Route";
import trucksRoutes from "pages/trucks/Route";
import React from "react";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* Dashboard Start */}
          <Route path="/*" element={<AppLayout />}>
              <Route
                path="dashboard"
                element={
                  <PrivateRoute>
                        <Dashboard />
                  </PrivateRoute>
                }
              />

              {companyRoutes()}

              {billsRoutes()}

              {trucksRoutes()}

              {tripsRoutes()}

            <Route path="403" element={<Forbidden />} />
            <Route path="*" element={<NotFound />} />
          </Route>

          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
