import Modal from "components/utils/Modal";
import moment from "moment";
import { useEffect, useState } from "react";
import Select from "react-select";
import BillOrderService from "services/BillOrderService";
import OptionsService from "services/OptionsService";
import React from "react";

const CreateCompany = ({ open, onCloseModal, onCreated }) => {
  const [data, setData] = useState({vat: 0, date: moment(new Date()).format('YYYY-MM-DD')});
  const [companiesOptions, setCompaniesOptions] = useState([]);
  const additionalFieldsOptions = [
    { value: 1, label: "Mawb No" },
    { value: 0, label: "Remarks" },
  ];

  const getCompaniesOptions = async () => {
    const res = await OptionsService.companies();
    setCompaniesOptions(res);
  };

  useEffect(() => {
    getCompaniesOptions();
  }, []);

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setData({
      ...data,
      [name]: value,
    });
  };

  const handleOption = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const createCompany = async () => {
    await BillOrderService.create(data);
    setData({vat: 0, date: moment(new Date()).format('YYYY-MM-DD')});
    onCreated();
    onCloseModal();
  };

  return (
    <div>
      <Modal
        size="xl"
        open={open}
        onCloseModal={onCloseModal}
        title={<>Add Company</>}
        body={
          <>
            <form id="create-company">
              <div className="row">
                <div className="col-md-3">
                  <div className="mb-5 fv-row fv-plugins-icon-container">
                    <label className="form-label">Select a Company</label>
                    <Select
                    options={companiesOptions}
                    onChange={(e)=>handleOption('company_id', e?.value)}
                    value={companiesOptions?.find((i)=>i.value===data?.company_id)}
                    name="company_id"
                  />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="mb-5 fv-row fv-plugins-icon-container">
                    <label className="form-label">Date</label>
                    <input
                      type="date"
                      className="form-control mb-2"
                      placeholder="Select a Date"
                      name="date"
                      value={data?.date}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-5 fv-row fv-plugins-icon-container">
                    <label className="form-label">VAT (%)</label>
                    <input
                      type="text"
                      className="form-control mb-2"
                      placeholder="Enter VAT"
                      name="vat"
                      value={data?.vat}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-5 fv-row fv-plugins-icon-container">
                    <label className="form-label">Additional Fields</label>
                    <Select
                    options={additionalFieldsOptions}
                    onChange={(e)=>handleOption('is_mawb', e?.value)}
                    value={additionalFieldsOptions?.find((i)=>i.value===data?.is_mawb)}
                    name="is_mawb"
                  />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-5 fv-row fv-plugins-icon-container">
                    <label className="form-label">Reference</label>
                    <input
                      type="text"
                      className="form-control mb-2"
                      placeholder="Enter Reference"
                      name="ref"
                      value={data?.ref}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-5 fv-row fv-plugins-icon-container">
                    <label className="form-label">PO Number</label>
                    <input
                      type="text"
                      className="form-control mb-2"
                      placeholder="Enter PO Number"
                      name="po"
                      value={data?.po}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              

              <button
                type="reset"
                className="btn btn-primary mr-2 mt-5"
                style={{ marginRight: "1rem" }}
                onClick={createCompany}
              >
                Submit
              </button>
              <button
                type="reset"
                className="btn btn-secondary  mt-5 "
                onClick={onCloseModal}
              >
                Cancel
              </button>
            </form>
          </>
        }
      />
    </div>
  );
};

export default CreateCompany;
