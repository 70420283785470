import http from "../http-common";

const getAll = async (params) => {
  const res = await http.get("/trips", {
    params: params
  });
  return res.data;
};

const get = async (id) => {
  const res = await http.get(`/trips/${id}`);
  return res.data;
};

const create = async (data) => {
  return http.post("/trips", data);
};

const update = async (id, data) => {
  const res = await http.put(`/trips/${id}`, data);
  return res.data;
};

const remove = async (id) => {
  const res = await http.delete(`/trips/${id}`);
  return res.data;
};


const Tripservice = {
  getAll,
  get,
  create,
  update,
  remove,
};

export default Tripservice;