import Modal from "components/utils/Modal";
import { useEffect, useState } from "react";
import React from "react";
import BillService from "services/BillService";

const Edit = ({ open, id, is_mawb, default_demurrage, onCloseModal, onUpdated }) => {
  const [data, setData] = useState({});

  const getBillOrder = async () => {
    setData(await BillService.get(id));
  };

  const updateBillOrder = async () => {
    await BillService.update(id, data);
    onUpdated();
    onCloseModal();
  };

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setData({
      ...data,
      [name]: value,
    });
  };

  useEffect(() => {
    if (id) getBillOrder();
  }, [id]);

  console.log(default_demurrage);
  console.log(data?.over_hrs);


  useEffect(() => {
    setData({
      ...data,
      demurrage: default_demurrage * data?.over_hrs,
    });
  }
  , [data?.over_hrs]);

  return (
    <div>
      <Modal
        size="xl"
        open={open}
        onCloseModal={onCloseModal}
        title={<>Update Bill</>}
        body={
          <>
            <form id="create-company">
            <div className="row">
                <div className="col-md-3">
                <div className="fw-bolder ">Date</div>
                <input
                  type="date"
                  className="form-control mb-2"
                  placeholder="Enter Date"
                  name="date"
                  value={data?.date}
                  onChange={handleChange}
                />
                </div>
                <div className="col-md-3">
                <div className="fw-bolder">Challan No</div>
                <input
                  type="text"
                  className="form-control mb-2"
                  placeholder="Enter Challan No"
                  name="challan_no"
                  value={data?.challan_no}
                  onChange={handleChange}
                />
                </div>
                <div className="col-md-3">
                <div className="fw-bolder">Truck No</div>
                <input
                  type="text"
                  className="form-control mb-2"
                  placeholder="Enter Truck No"
                  name="truck_no"
                  value={data?.truck_no}
                  onChange={handleChange}
                />
                </div>
                {is_mawb ? <div className="col-md-3">
                <div className="fw-bolder">Mawb no</div>
                <input
                  type="text"
                  className="form-control mb-2"
                  placeholder="Enter Mawb no"
                  name="mawb_no"
                  value={data?.mawb_no}
                  onChange={handleChange}
                />
                </div> : null}
                <div className="col-md-3">
                <div className="fw-bolder">over(hrs)</div>
                <input
                  type="number"
                  className="form-control mb-2"
                  placeholder="Enter over(hrs)"
                  name="over_hrs"
                  value={data?.over_hrs}
                  onChange={handleChange}
                />
                </div>
                <div className="col-md-3">
                <div className="fw-bolder">Demurrage</div>
                <input
                  type="number"
                  className="form-control mb-2"
                  placeholder="Enter Demurrage"
                  name="demurrage"
                  value={data?.demurrage}
                  onChange={handleChange}
                />
                </div>
                <div className="col-md-3">
                <div className="fw-bolder">Parking</div>
                <input
                  type="number"
                  className="form-control mb-2"
                  placeholder="Enter Parking"
                  name="parking"
                  value={data?.parking}
                  onChange={handleChange}
                />
                </div>
                <div className="col-md-3">
                <div className="fw-bolder">Rent</div>
                <input
                  type="number"
                  className="form-control mb-2"
                  placeholder="Enter Rent"
                  name="rent"
                  value={data?.rent}
                  onChange={handleChange}
                />
                </div>
                {!is_mawb ? <div className="col-md-3">
                <div className="fw-bolder">Remarks</div>
                <input
                  type="text"
                  className="form-control mb-2"
                  placeholder="Enter Remarks"
                  name="mawb_no"
                  value={data?.mawb_no}
                  onChange={handleChange}
                />
                </div> : null}
              </div>
              <button
                type="reset"
                className="btn btn-primary mr-2 mt-5"
                style={{ marginRight: "1rem" }}
                onClick={updateBillOrder}
              >
                Update
              </button>
              <button
                type="reset"
                className="btn btn-secondary  mt-5 "
                onClick={onCloseModal}
              >
                Cancel
              </button>
            </form>
          </>
        }
      />
    </div>
  );
};

export default Edit;
