import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import moment from 'moment';
import logo from './vat.png';
import React from 'react';

const PrintVAT = ({billOrder, bills, invoiceNo, invoiceDate}) => {
    const styles = StyleSheet.create({
        page: {fontSize: 11,paddingTop: 20,paddingLeft: 40,paddingRight: 40,lineHeight: 1.5,flexDirection: 'column' },    
    
        theader : {
            paddingHorizontal: 2,
            borderTop: 1,
            borderBottom: 1,
            borderLeft: 1,
            flex:1,
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
        },
        theader2 : {
            paddingHorizontal: 2,
            borderBottom: 1,
            borderLeft: 1,
            flex:1,
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
        },
        
    });

    const InvoiceTitle = () => (
        <View style={{flexDirection: 'row',marginTop: 40, justifyContent: 'space-between', alignItems: 'center'}}>
            <Image style={{ width: 40 }} src={logo} />
            <View style={{textAlign: 'center', alignItems: 'center'}}>
                    <Text style={{color:'#0070C0', fontSize:8}}>Government of Peoples&apos; Republic of Bangladesh</Text>
                    <Text style={{color:'#0070C0', fontSize:10, fontWeight: "bold"}}>National Board of Revenue</Text>
                    <Text style={{color:'#6F30A0', fontSize:10, fontWeight: "bold"}}>VAT Invoice</Text>
                    <Text style={{color:'#0070C0', fontSize:6}}>[As per Para (Ga) and Para (Cha) of Subrule 1 of Rule 40]</Text>
                </View>
                <Text style={{border:1, paddingVertical:2, paddingHorizontal:10, borderColor: '#0070C0', lineHeight: 1, color: "#04B050", fontWeight: "700"}}>Mushak - 6.3</Text>
        </View>
    );

    const Address = () => (
        <View style={{marginTop: 40}}>
            <View style={{alignItems: 'center'}}>
                <View>
                    <Text style={{fontSize:6}}>Name of Registered Person: Prime Logistic Services</Text>
                    <Text style={{fontSize:6}}>BIN of Registered Person: 000767647-0203</Text>
                    <Text style={{fontSize:6}}>Address of Invoice Issuing: 7/I, Old FDC Road, Janata Bank Bulding 1st floor, Real Gate, Tejgaon, Dhaka-1208</Text>
                </View>
            </View>
            <View style={{flexDirection: 'row',marginTop: 40, justifyContent: 'space-between', paddingLeft:20, paddingRight:60}}>
                <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                    <View>
                        <Text style={{fontSize:6}}>Name of Purchaser</Text>
                        <Text style={{fontSize:6}}>Purchaser&apos;s BIN</Text>
                        <Text style={{fontSize:6}}>Address of Purchaser</Text>
                        <Text style={{fontSize:6}}>Address of Destination</Text>
                        <Text style={{fontSize:6}}>Nature and Number of Vehicle</Text>
                    </View>
                    <View>
                        <Text style={{fontSize:6, paddingLeft: 5}}>:    {billOrder?.company?.name}</Text>
                        <Text style={{fontSize:6, paddingLeft: 5}}>:    {billOrder?.company?.bin}</Text>
                        <Text style={{fontSize:6, paddingLeft: 5}}>:    {billOrder?.company?.address?.replace(/\n|\r/g, ", ")}</Text>
                        <Text style={{fontSize:6, paddingLeft: 5}}>:</Text>
                        <Text style={{fontSize:6, paddingLeft: 5}}>:</Text>
                    </View>
                </View>
                <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                    <View>
                        <Text style={{fontSize:6}}>Mushak Invoice No.</Text>
                        <Text style={{fontSize:6}}>Date of Issue</Text>
                        <Text style={{fontSize:6}}>Time of Issue</Text>
                        <Text style={{fontSize:6}}></Text>
                        <Text style={{fontSize:6}}></Text>
                    </View>
                    <View>
                        <Text style={{fontSize:6, paddingLeft: 5}}>:    {invoiceNo}</Text>
                        <Text style={{fontSize:6, paddingLeft: 5}}>:    {moment(invoiceDate).format("DD-MMM-YY")}</Text>
                        <Text style={{fontSize:6, paddingLeft: 5}}>:    2:50 PM</Text>
                    </View>
                </View>
            </View>
        </View>
    );

    const TableHead = () => (
        <View>
            <View style={{ width:'100%', flexDirection :'row', marginTop:24}}>
                <View style={{...styles.theader, flex:0.5}}>
                    <Text style={{fontSize: 6}}>SL No.</Text>   
                </View>
                <View style={{...styles.theader, flex:2}}>
                    <Text style={{fontSize: 6}}>Description of Goods / Services (including Brand name if applicable)</Text>   
                </View>
                <View style={{...styles.theader, flex:1}}>
                    <Text style={{fontSize: 6}}>Unit of Supply</Text>   
                </View>
                <View style={{...styles.theader, flex:1}}>
                    <Text style={{fontSize: 6}}>Quantity</Text>   
                </View>
                <View style={{...styles.theader, flex:1}}>
                    <Text style={{fontSize: 6}}>Unit price * (in Tk.)</Text>   
                </View>
                <View style={{...styles.theader, flex:1.5}}>
                    <Text style={{fontSize: 6}}>Total Value (in Tk.)</Text>   
                </View>
                <View style={{...styles.theader, flex:1}}>
                    <Text style={{fontSize: 6}}>Rate of Supplementary Duty</Text>   
                </View>
                <View style={{...styles.theader, flex:1.5}}>
                    <Text style={{fontSize: 6}}>Amount of Supplementary Duty (in Tk.)</Text>   
                </View>
                <View style={{...styles.theader, flex:1}}>
                    <Text style={{fontSize: 6}}>VAT Rate / Specified VAT</Text>   
                </View>
                <View style={{...styles.theader, flex:1.5}}>
                    <Text style={{fontSize: 6}}>Amount of VAT/Specified VAT (In Tk.)</Text>   
                </View>
                <View style={{...styles.theader, flex:1.5, borderRight: 1}}>
                    <Text style={{fontSize: 6}}>Value including all kind of SD and VAT (in Tk.)</Text>   
                </View>
            </View>
            <View style={{ width:'100%', flexDirection :'row'}}>
            <View style={{...styles.theader2, flex:0.5}}>
                <Text style={{fontSize: 6}}>1</Text>   
            </View>
            <View style={{...styles.theader2, flex:2}}>
                <Text style={{fontSize: 6}}>2</Text>   
            </View>
            <View style={{...styles.theader2, flex:1}}>
                <Text style={{fontSize: 6}}>3</Text>   
            </View>
            <View style={{...styles.theader2, flex:1}}>
                <Text style={{fontSize: 6}}>4</Text>   
            </View>
            <View style={{...styles.theader2, flex:1}}>
                <Text style={{fontSize: 6}}>5</Text>   
            </View>
            <View style={{...styles.theader2, flex:1.5}}>
                <Text style={{fontSize: 6}}>6 = 4 * 5</Text>   
            </View>
            <View style={{...styles.theader2, flex:1}}>
                <Text style={{fontSize: 6}}>7</Text>   
            </View>
            <View style={{...styles.theader2, flex:1.5}}>
                <Text style={{fontSize: 6}}>8 = 6 * 7</Text>   
            </View>
            <View style={{...styles.theader2, flex:1}}>
                <Text style={{fontSize: 6}}>9</Text>   
            </View>
            <View style={{...styles.theader2, flex:1.5}}>
                <Text style={{fontSize: 6}}>10 = (6 + 8) * 9</Text>   
            </View>
            <View style={{...styles.theader2, flex:1.5, borderRight: 1}}>
                <Text style={{fontSize: 6}}>11 = 6 + 8 + 10</Text>   
            </View>
            </View> 
        </View>
    );


    const TableBody = () => (
        <View>
            <View style={{ width:'100%', flexDirection :'row', height: 240}}>
                <View style={{...styles.theader2, flex:0.5}}>
                    <Text style={{fontSize: 6}}>1</Text>   
                </View>
                <View style={{...styles.theader2, flex:2}}>
                    <Text style={{fontSize: 6}}>Transport Service</Text>   
                </View>
                <View style={{...styles.theader2, flex:1}}>
                    <Text style={{fontSize: 6}}></Text>   
                </View>
                <View style={{...styles.theader2, flex:1}}>
                    <Text style={{fontSize: 6}}>1</Text>   
                </View>
                <View style={{...styles.theader2, flex:1}}>
                    <Text style={{fontSize: 6}}>
                    {bills.reduce((a, b) => a + parseFloat(b?.demurrage) + parseFloat(b?.parking) + parseFloat(b?.rent), 0).toFixed(2)}
                    </Text>   
                </View>
                <View style={{...styles.theader2, flex:1.5}}>
                    <Text style={{fontSize: 6}}>
                        {bills.reduce((a, b) => a + parseFloat(b?.demurrage) + parseFloat(b?.parking) + parseFloat(b?.rent), 0).toFixed(2)}
                    </Text>   
                </View>
                <View style={{...styles.theader2, flex:1}}>
                    <Text style={{fontSize: 6}}>0</Text>   
                </View>
                <View style={{...styles.theader2, flex:1.5}}>
                    <Text style={{fontSize: 6}}>-</Text>   
                </View>
                <View style={{...styles.theader2, flex:1}}>
                    <Text style={{fontSize: 6}}>{billOrder?.vat}%</Text>   
                </View>
                <View style={{...styles.theader2, flex:1.5}}>
                    <Text style={{fontSize: 6}}>
                    {(bills.reduce((a, b) => a + parseFloat(b?.demurrage) + parseFloat(b?.parking) + parseFloat(b?.rent), 0).toFixed(0) * (billOrder?.vat / 100)).toFixed(2)}
                    </Text>   
                </View>
                <View style={{...styles.theader2, flex:1.5, borderRight: 1}}>
                    <Text style={{fontSize: 6}}>
                    {(bills.reduce((a, b) => a + parseFloat(b?.demurrage) + parseFloat(b?.parking) + parseFloat(b?.rent), 0).toFixed(0) * ((billOrder?.vat / 100) + 1)).toFixed(2)}
                    </Text>   
                </View>
            </View> 
            <View style={{ width:'100%', flexDirection :'row'}}>
                <View style={{...styles.theader2, flex:0.5}}>
                    <Text style={{fontSize: 6}}></Text>   
                </View>
                <View style={{...styles.theader2, flex:2, borderLeftColor: "#FFF"}}>
                    <Text style={{fontSize: 6}}>Total = BDT</Text>   
                </View>
                <View style={{...styles.theader2, flex:1, borderLeftColor: "#FFF"}}>
                    <Text style={{fontSize: 6}}></Text>   
                </View>
                <View style={{...styles.theader2, flex:1, borderLeftColor: "#FFF"}}>
                    <Text style={{fontSize: 6}}></Text>   
                </View>
                <View style={{...styles.theader2, flex:1, borderLeftColor: "#FFF"}}>
                    <Text style={{fontSize: 6}}></Text>   
                </View>
                <View style={{...styles.theader2, flex:1.5}}>
                    <Text style={{fontSize: 6}}>
                        {bills.reduce((a, b) => a + parseFloat(b?.demurrage) + parseFloat(b?.parking) + parseFloat(b?.rent), 0).toFixed(2)}
                    </Text>   
                </View>
                <View style={{...styles.theader2, flex:1}}>
                    <Text style={{fontSize: 6}}></Text>   
                </View>
                <View style={{...styles.theader2, flex:1.5}}>
                    <Text style={{fontSize: 6}}>-</Text>   
                </View>
                <View style={{...styles.theader2, flex:1}}>
                    <Text style={{fontSize: 6}}>-</Text>   
                </View>
                <View style={{...styles.theader2, flex:1.5}}>
                    <Text style={{fontSize: 6}}>
                    {(bills.reduce((a, b) => a + parseFloat(b?.demurrage) + parseFloat(b?.parking) + parseFloat(b?.rent), 0).toFixed(0) * (billOrder?.vat / 100)).toFixed(2)}
                    </Text>   
                </View>
                <View style={{...styles.theader2, flex:1.5, borderRight: 1}}>
                    <Text style={{fontSize: 6}}>
                    {(bills.reduce((a, b) => a + parseFloat(b?.demurrage) + parseFloat(b?.parking) + parseFloat(b?.rent), 0).toFixed(0) * ((billOrder?.vat / 100) + 1)).toFixed(2)}
                    </Text>   
                </View>
            </View> 
        </View>
    );

    const Footer = () => (
        <View>
        <View style={{flexDirection: 'row', marginTop: 40, paddingLeft:20}}>
                <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                    <View>
                        <Text style={{fontSize:6}}>Name of Authorised Person</Text>
                        <Text style={{fontSize:6}}>Designation</Text>
                        <Text style={{fontSize:6}}>Signature</Text>
                        <Text style={{fontSize:6}}>Seal</Text>
                    </View>
                    <View>
                        <Text style={{fontSize:6, paddingLeft: 5}}>:    Md. Shahin Mridha</Text>
                        <Text style={{fontSize:6, paddingLeft: 5}}>:    Proprietor</Text>
                        <Text style={{fontSize:6, paddingLeft: 5}}>:</Text>
                        <Text style={{fontSize:6, paddingLeft: 5}}>:</Text>
                    </View>
                </View>
            </View>
                <Text style={{fontSize:6, marginTop: 40, paddingLeft:20}}>* Value exclusive all taxes i.e. SD  and VAT</Text>
                </View>
    );

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <InvoiceTitle  />
                <Address/>
                <TableHead/>
                <TableBody/>
                <Footer/>
            </Page>
        </Document>
          
    )
}

export default PrintVAT;