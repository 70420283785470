import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CompanyService from "services/CompanyService";
import React from "react";

const ShowCompany = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  
  const [company, setCompany] = useState({});
  const getCompany = async () => {
    setCompany(await CompanyService.get(id));
  };

  useEffect(() => {
    getCompany();
  }, [id]);

  return (
    <div className="post d-flex flex-column-fluid" id="kt_post">
      <div id="kt_content_container" className="container-xxl">
        <div className="form d-flex flex-column flex-lg-row gap-7 gap-lg-10 fv-plugins-bootstrap5 fv-plugins-framework">
          <div className="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px">
        <div className="card card-custom">
          <div className="card-header h-auto py-4 mb-2">
            <div className="card-title">
              <h3 className="card-label">
                <button
                  className="btn btn-sm btn-dark "
                  style={{ marginRight: "0.75rem" }}
                  onClick={() => navigate(-1)}
                >
                  <i className="fa fa-arrow-left"></i>Back
                </button>
                Company Details
              </h3>
            </div>
          </div>

          <div className="card-body pt-0">
            <div className="text-center">
              <div className="fs-7">
                <h2>{company.name}</h2>
              </div>  
            </div>
            <div className="collapse show text-left">
              <div className="py-5 fs-6">
              <div className="fw-bolder">Owner Name</div>
                <div className="text-gray-600">{company.owner_name}</div>
                <div className="fw-bolder">Owner Email</div>
                <div className="text-gray-600">{company.owner_email}</div>
                <div className="fw-bolder">Owner Phone</div>
                <div className="text-gray-600">{company.owner_phone}</div>
              </div>
            </div>

            <div className="collapse show text-left">
              <div className="py-5 fs-6">
                <div className="fw-bolder">Contact Person Name</div>
                <div className="text-gray-600">{company.contact_person_name}</div>
                <div className="fw-bolder">Contact Person Email</div>
                <div className="text-gray-600">{company.contact_person_email}</div>
                <div className="fw-bolder">Contact Person Phone</div>
                <div className="text-gray-600">{company.contact_person_phone}</div>
              </div>
            </div>

          </div>
        </div>
      </div>

          <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
            Afnan
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowCompany;
