import { PrivateRoute } from "helpers/PrivateRoute";
import Companies from "pages/companies/Index";
import ShowCompany from "pages/companies/Show";
import { Route } from "react-router-dom";
import React from "react";

const index = () => {
    return (<>
      <Route
        path="companies"
        element={
          <PrivateRoute>
            <Companies />
          </PrivateRoute>
        }
      />
      <Route
        path="companies/:id"
        element={
          <PrivateRoute>
            <ShowCompany />
          </PrivateRoute>
        }
      />
    </>);
}

export default index;