import Modal from "components/utils/Modal";
import { useEffect, useState } from "react";
import Select from "react-select";
import OptionsService from "services/OptionsService";
import TripService from "services/TripService";
import React from "react";

const Create = ({ open, onCloseModal, onCreated }) => {
  const [data, setData] = useState({});
  const [trucks, setTrucks] = useState([]);

  const getTrucks = async () => {
    setTrucks(await OptionsService.trucks());
  };

  useEffect(() => {
    getTrucks();
  }, [open]);

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setData({
      ...data,
      [name]: value,
    });
  };

  const handleOption = (name, value, phone) => {
    setData({
      ...data,
      [name]: value,
      'driver_phone': phone,
    });
  };

  const createData = async () => {
    await TripService.create(data);
    setData({});
    onCreated();
    onCloseModal();
  };

  return (
    <div>
      <Modal
        size="xl"
        open={open}
        onCloseModal={onCloseModal}
        title={<>Add Trip</>}
        body={
          <>
            <form id="create-company">
              <div className="row">
                <div className="col-md-3">
                  <div className="mb-5 fv-row fv-plugins-icon-container">
                    <label className="form-label">Trip Date</label>
                    <input
                      type="date"
                      className="form-control mb-2"
                      placeholder="Enter Truck Number"
                      name="date"
                      value={data?.date}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-5 fv-row fv-plugins-icon-container">
                    <label className="form-label">Select a Truck</label>
                      <Select
                        options={trucks}
                        onChange={(e)=>handleOption('truck_id', e?.value, e?.phone)}
                        value={trucks?.find((i)=>i.value===data?.truck_id)}
                        name="truck_id"
                      />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-5 fv-row fv-plugins-icon-container">
                    <label className="form-label">Driver Name</label>
                    <input
                      type="text"
                      className="form-control mb-2"
                      name="driver_name"
                      placeholder="Enter Driver Name"
                      value={data?.driver_name}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-5 fv-row fv-plugins-icon-container">
                    <label className="form-label">Driver Phone No</label>
                    <input
                      type="text"
                      className="form-control mb-2"
                      name="driver_phone"
                      placeholder="Enter Driver Phone"
                      value={data?.driver_phone}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-5 fv-row fv-plugins-icon-container">
                    <label className="form-label">Company Name</label>
                    <input
                      type="text"
                      className="form-control mb-2"
                      name="company_name"
                      placeholder="Company Name"
                      value={data?.company_name}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-5 fv-row fv-plugins-icon-container">
                    <label className="form-label">Company Phone</label>
                    <input
                      type="text"
                      className="form-control mb-2"
                      name="company_phone"
                      placeholder="Company Phone"
                      value={data?.company_phone}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-5 fv-row fv-plugins-icon-container">
                    <label className="form-label">Contact Person Name</label>
                    <input
                      type="text"
                      className="form-control mb-2"
                      name="company_contact_person"
                      placeholder="Enter Name"
                      value={data?.company_contact_person}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-5 fv-row fv-plugins-icon-container">
                    <label className="form-label">Contact Person Phone</label>
                    <input
                      type="text"
                      className="form-control mb-2"
                      name="company_contact_person_phone"
                      placeholder="Enter Phone"
                      value={data?.company_contact_person_phone}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-5 fv-row fv-plugins-icon-container">
                    <label className="form-label">Load Point</label>
                    <input
                      type="text"
                      className="form-control mb-2"
                      name="loading_point"
                      placeholder="Enter Load Point"
                      value={data?.loading_point}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-5 fv-row fv-plugins-icon-container">
                    <label className="form-label">Unload Point</label>
                    <input
                      type="text"
                      className="form-control mb-2"
                      name="unloading_point"
                      placeholder="Enter Unloading Point"
                      value={data?.unloading_point}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-5 fv-row fv-plugins-icon-container">
                    <label className="form-label">Rent</label>
                    <input
                      type="text"
                      className="form-control mb-2"
                      name="rent"
                      placeholder="Enter Rent"
                      value={data?.rent}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                </div>
                <div className="col-md-6">
                  <div className="mb-5 fv-row fv-plugins-icon-container">
                    <label className="form-label">Comment</label>
                    <textarea
                      rows="3"
                      type="text"
                      className="form-control"
                      placeholder="Enter Comment"
                      name="comment"
                      value={data?.comment}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <button
                type="reset"
                className="btn btn-primary mr-2 mt-5"
                style={{ marginRight: "1rem" }}
                onClick={createData}
              >
                Submit
              </button>
              <button
                type="reset"
                className="btn btn-secondary  mt-5 "
                onClick={onCloseModal}
              >
                Cancel
              </button>
            </form>
          </>
        }
      />
    </div>
  );
};

export default Create;
