import http from "../http-common";

const companies = async () => {
  const res = await http.get("/options/companies");
  return res.data;
};

const trucks = async () => {
  const res = await http.get("/options/trucks");
  return res.data;
}

const OptionsService = {
  companies,
  trucks
};

export default OptionsService;