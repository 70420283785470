import http from "../http-common";

const getAll = async (id, params) => {
  const res = await http.get(`/bills`, {params: {...params, bill_order_id: id}});
  return res.data;
}

const get = async (id) => {
  const res = await http.get(`/bills/${id}`);
  return res.data;
};

const create = async (id, data) => {
  const res = await http.post(`/bills`, {...data, bill_order_id: id});
  return res.data;
}

const update = async (id, data) => {
  const res = await http.put(`/bills/${id}`, data);
  return res.data;
};

const remove = async (id) => {
  const res = await http.delete(`/bills/${id}`);
  return res.data;
};

const BillService = {
  getAll,
  get,
  create,
  update,
  remove,
};

export default BillService;