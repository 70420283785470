import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import OptionsService from "services/OptionsService";
import React from "react";

const Filter = ({ enable, onClickOutside, onChange }) => {
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target))
        onClickOutside && onClickOutside();
    };

    document.addEventListener("click", handleClickOutside, true);
    return () =>
      document.removeEventListener("click", handleClickOutside, true);
  }, [onClickOutside]);

  const [data, setData] = useState({});
  const [companyOptions, setCompanyOptions] = useState([]);
  const statusOptions = [
      { value: "Pending", label: "Pending" },
      { value: "Due", label: "Due" },
      { value: "Paid", label: "Paid" }
    ];

  const getCompanyOptions = async () => {
    setCompanyOptions(await OptionsService.companies());
  };

  useEffect(() => {
    if (enable) {
      getCompanyOptions();
    }
  }, [enable]);

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSelect = (option, action) => {
    const value = option.value;
    const name = action.name;
    setData({ ...data, [name]: value });
  };

  const apply = () => {
    typeof onChange === "function" && onChange(data);
    onClickOutside();
  };

  const reset = () => {
    setData(null);
    typeof onChange === "function" &&
      onChange({
        date: null,
        truck_id: null,
        status: null,
      });
      onClickOutside();
  };

  if (!enable) return null;

  let custom = {
    zIndex: 105,
    position: "fixed",
    inset: "0px 0px auto auto",
    margin: 0,
    transform: "translate(-100%, 20%)",
  };

  return (
    <div
      ref={ref}
      className="menu menu-sub menu-sub-dropdown w-250px w-md-300px show"
      style={custom}
    >
      <div className="px-7 py-5">
        <div className="fs-5 text-dark fw-bolder">Filter Options</div>
      </div>
      <div className="separator border-gray-200"></div>
      <div className="px-7 py-5">
        <div className="mb-10">
          <label className="form-label fw-bold">Select Date</label>
          <input
                      type="date"
                      className="form-control mb-2"
                      placeholder="Enter Truck Number"
                      name="date"
                      value={data?.date}
                      onChange={handleChange}
                    />
        </div>
        <div className="mb-10">
          <label className="form-label fw-bold">Select Company</label>
          <Select
            options={companyOptions}
            onChange={(e) => handleSelect(e, { name: 'company_id' })}
            name="company_id"
            value={companyOptions?.find((i) => i.value === data?.company_id)}
          />
        </div>
        <div className="mb-10">
          <label className="form-label fw-bold">Select Status</label>
          <Select
            options={statusOptions}
            onChange={(e) => handleSelect(e, { name: 'status' })}
            name="status"
            value={statusOptions?.find((i) => i.value === data?.status)}
          />
        </div>
        <div className="d-flex justify-content-end">
          <button
            onClick={reset}
            type="button"
            className="btn btn-sm btn-light btn-active-light-primary me-2"
          >
            Reset
          </button>
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={() => {
              apply();
            }}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
}

export default Filter;
