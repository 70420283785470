import Modal from "components/utils/Modal";
import { useState } from "react";
import TruckService from "services/TruckService";
import React from "react";

const Create = ({ open, onCloseModal, onCreated }) => {
  const [data, setData] = useState({});

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setData({
      ...data,
      [name]: value,
    });
  };

  const createData = async () => {
    await TruckService.create(data);
    setData({});
    onCreated();
    onCloseModal();
  };

  return (
    <div>
      <Modal
        size="xl"
        open={open}
        onCloseModal={onCloseModal}
        title={<>Add Truck</>}
        body={
          <>
            <form id="create-company">
              <div className="row">
              <div className="col-md-4">
                  <div className="mb-5 fv-row fv-plugins-icon-container">
                    <label className="form-label">Truck Number</label>
                    <input
                      type="text"
                      className="form-control mb-2"
                      placeholder="Enter Truck Number"
                      name="truck_no"
                      value={data?.truck_no}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-5 fv-row fv-plugins-icon-container">
                    <label className="form-label">Phone Number</label>
                    <input
                      type="text"
                      className="form-control mb-2"
                      placeholder="Enter Phone Number"
                      name="phone"
                      value={data?.phone}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-4"></div>
                <div className="col-md-3">
                  <div className="mb-5 fv-row fv-plugins-icon-container">
                    <label className="form-label">Tax Token Expiry</label>
                    <input
                      type="date"
                      className="form-control mb-2"
                      name="tax_token_expiry"
                      value={data?.tax_token_expiry}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-5 fv-row fv-plugins-icon-container">
                    <label className="form-label">Fitness Date Expiry</label>
                    <input
                      type="date"
                      className="form-control mb-2"
                      name="fitness_expiry"
                      value={data?.fitness_expiry}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-5 fv-row fv-plugins-icon-container">
                    <label className="form-label">Insurance Expiry</label>
                    <input
                      type="date"
                      className="form-control mb-2"
                      name="insurance_expiry"
                      value={data?.insurance_expiry}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-5 fv-row fv-plugins-icon-container">
                    <label className="form-label">Permit Expiry</label>
                    <input
                      type="date"
                      className="form-control mb-2"
                      name="permit_expiry"
                      value={data?.permit_expiry}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <button
                type="reset"
                className="btn btn-primary mr-2 mt-5"
                style={{ marginRight: "1rem" }}
                onClick={createData}
              >
                Submit
              </button>
              <button
                type="reset"
                className="btn btn-secondary  mt-5 "
                onClick={onCloseModal}
              >
                Cancel
              </button>
            </form>
          </>
        }
      />
    </div>
  );
};

export default Create;
