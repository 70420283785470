import Confirmation from "components/utils/Confirmation";
import Table from "components/utils/Table";
import { useEffect, useState } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import BillOrderService from "services/BillOrderService";
import CreateBill from "./Create";
import EditBill from "./Edit";
import Filter from "./Filter";
import React from "react";


const Index = () => {
  const [loading, setLoading] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [data, setData] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [companyId, setcompanyId] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [enableFilter, setEnableFilter] = useState(false);

  const columns = [
    {
      name: "ID",
      selector: (row) => row?.id,
      sortable: true,
      field: "id",
      format: (row) => (
        <div className="d-flex align-items-center">
          <div className="symbol symbol-50px me-5">
            {row?.id}
          </div>
        </div>
      ),
    },
    {
      name: "Company Name",
      selector: (row) => row?.company?.name,
      format: (row) => (
        <div className="d-flex align-items-center">
          <div className="symbol symbol-50px me-5">
            {row?.company?.name}
          </div>
        </div>
      ),
    },
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
      field: "date",
      format: (row) => (
        <div className="d-flex align-items-center">
          <div className="symbol symbol-50px me-5">
          <Moment format="DD-MMM-YYYY">{row?.date}</Moment>
          </div>
        </div>
      ),
    },
    {
      name: "Total Amount",
      selector: (row) => row.owner_name,
      format: (row) => (
        <div className="d-flex align-items-center">
          <div className="symbol symbol-50px me-5">
          <div>
          {row?.bills.length} bill&apos;s
          </div>
          <div>
            {row?.bills.reduce((acc, bill) => acc + parseFloat(bill?.demurrage) + parseFloat(bill?.parking) + parseFloat(bill?.rent), 0).toFixed(0)}
          </div>
          </div>
        </div>
      ),
    },
    {
      name: "Vat",
      selector: (row) => row.vat,
      format: (row) => (
        <div className="d-flex align-items-center">
          <div className="symbol symbol-50px me-5">
          <div>
            {row.vat}%
          </div>
          <div>
          {(row?.bills.reduce((acc, bill) => acc + parseFloat(bill?.demurrage) + parseFloat(bill?.parking) + parseFloat(bill?.rent), 0) * (row.vat / 100)).toFixed(0) }
          </div>
          </div>
        </div>
      ),
    },
    {
      name: "Grand Total",
      selector: (row) => row.owner_name,
      format: (row) => (
        <div className="d-flex align-items-center">
          <div className="symbol symbol-50px me-5">
          {(row?.bills.reduce((acc, bill) => acc + parseFloat(bill?.demurrage) + parseFloat(bill?.parking) + parseFloat(bill?.rent), 0) * ((row.vat / 100) + 1)).toFixed(0) }
          </div>
        </div>
      ),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      format: (row) => (
        <div className="d-flex align-items-center">
          <div className="symbol symbol-50px me-5">
          {row.status}
          </div>
        </div>
      ),
    },
    {
      name: "Action",
      selector: (row) => row.status,
      format: (row) => (
        <span className="text-end">
            <Link
              to={"/bills/" + row?.id}
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
            >
              <i className="fa fa-eye"></i>
            </Link>
            <Link 
              to="#"
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
              onClick={() => {
                setcompanyId(row?.id);
                setOpenEditModal(true);
              }}
            >
              <i className="fa fa-pen"></i>
            </Link>
            <Link
              to="#"
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
              onClick={() => {
                setcompanyId(row?.id);
                setConfirmDelete(true);
              }}
            >
              <i className="fa fa-trash"></i>
            </Link>
        </span>
      ),
    },
  ];

  const getData = async (filters) => {
    setData(await BillOrderService.getAll(filters));
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);

  const deleteData = async (companyId) => {
    await BillOrderService.remove(companyId);
    getData();
  };

  return (
    <>
      <div className="post d-flex flex-column-fluid">
        <div className="container-xxl">
          <Table
            name="by Company name"
            buttonName="Add Bill"
            onClickButton={() => setOpenAddModal(true)}
            isLoading={loading}
            data={data}
            columns={columns}
            onFilter={getData}
            callbackButtons={[
              {
                name: "Filter",
                callback: () => {
                  setEnableFilter(!enableFilter);
                },
                permission: null,
              },
            ]}
          />
        </div>
      </div>

      <CreateBill
        open={openAddModal}
        onCloseModal={() => setOpenAddModal(false)}
        onCreated={getData}
      />
      <EditBill
        open={openEditModal}
        id={companyId}
        onCloseModal={() => setOpenEditModal(false)}
        onUpdated={getData}
      />

      <Confirmation
        open={confirmDelete}
        onConfirm={() => {
          setConfirmDelete(false);
          deleteData(companyId);
        }}
        onCancel={() => setConfirmDelete(false)}
      /> 

      <Filter
        enable={enableFilter}
        onClickOutside={() => {
          setEnableFilter(!enableFilter);
        }}
        onChange={getData}
      />
    </>
  );
};

export default Index;
